import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { WaitObject } from '../../../scripts/Wait';
import { Req, Request } from '../../../scripts/Request';

import { Lista as Servers } from './Servers'
import { Requests } from './Requests';
import { Ellapsed } from './Ellapsed';
import { Errors } from './Errors';



export default function Home() { 
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [ servers, setServers] = useState([]);


    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    //#region Effects
    useEffect(() => {
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        LoadServers();
    }
    //endregion Init


    //#region Loaders
    const LoadServers = async() => {
        let _servers = await Req("/assets/json/servers.json", "GET", "", user.token, 0);
        setServers(_servers);
    }
    //#endregion Loaders



    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">


                    <div className="row">
                        <div className="col-lg-6">
                            <Servers data={servers} />

                            <Errors />
                        </div>

                        <div className="col-lg-6">
                            <Requests />

                            <Ellapsed />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}