import { Server } from './Server'
import './index.css';

export function Lista({...props}) { 

    const { data } = props

    return (
        <div className="card Servers">
            <div className="card-header justify-content-between d-flex align-items-center">
                <h4 className="card-title">Monitoramento de servidores</h4>
            </div>
            <div id="scroll_vendedores" className="card-body sellers_rank">                                          
                <div className="table-responsive">
                    <table className="table mb-0">
                        <tbody>
                            {Array.isArray(data) && data.length > 0 && data.map((item, i) => (
                                <tr key={'tr' + i} className="pointer">
                                    <Server index={i} content={item} />
                                </tr>
                            ))}
                        
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}