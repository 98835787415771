import { useState, useEffect, useRef } from "react";
import { Req, RequestGetPlain} from '../../../../scripts/Request';

import './Server.css'

const timer_timeout = 60;
let chartData = [];
let serverStatus = [];
let timers = [];

export function Server({...props}) { 
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const chartindex = useRef(0);

    const { index, content } = props;
    const [status, setStatus] = useState(serverStatus[index]||false);

    //#region Effects
    useEffect(() => {
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                serverStatus[index] = false;
                chartData[index] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                window.draw_sparklines(index, chartData[index] , 200, 40, ['#008800']);
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    clearTimeout(timers[index]);
                } catch(e) {}
            }
        }
    },[]);


    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadPing();
    }
    //#endregion Init


    //#region Loaders
    const LoadPing = async() => {
        let ms = 0;
        let _colors = ['#008800'];
        let _ini = new Date();

        try {
            let _ping = await Req(content.api, "GET", "", "", 0);
            //console.log("OK", content.api, index, serverStatus[index]);
            serverStatus[index] = true;
            setStatus(serverStatus[index]);
            let _fim = new Date();
            let timeDiff = _fim - _ini;
            ms = Math.round(timeDiff);
            _colors = [ms < 700 ? '#008800' : (ms < 2800 ? '#FFBF00': '#880000')];
        } catch(err) {
            //console.log("ERR", content.api, index, serverStatus[index]);
            serverStatus[index] = false;
            setStatus(serverStatus[index]);
            _colors = ['#880000'];
        }
           

        let _data = chartData[index];
        let _index = chartindex.current % 12;
        _data.unshift();
        _data.push(ms);
       
        chartData[index] = _data;
        chartindex.current = _index + 1;

        window.draw_sparklines(index, _data, 200, 40, _colors);

        timers[index] = setTimeout(LoadPing, timer_timeout*1000);
    }
    //#endregion Loaders



    return (
        <>
            <td>
                {content?.name}
            </td>

            <td>
                {serverStatus[index] ? (<p class="mb-0"><i class="mdi mdi-square-rounded font-size-10 text-success me-2"></i>Online</p>) 
                : (<p class="mb-0"><i class="mdi mdi-square-rounded font-size-10 text-danger me-2"></i>Offline</p>)} 
            </td>

            <td className="timeline-ping">
                <div id={'chart-sparkline'+index} data-colors='["--bs-primary"]' class="apex-charts"></div>
            </td>
        </>
    )
}