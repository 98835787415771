import { useState, useEffect, useRef, useContext } from "react";
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { WaitObject } from '../../../../scripts/Wait';
import { Req, Request } from '../../../../scripts/Request';
import moment from "moment/moment";
import './index.css';


let timer_requests;
let current_element_errors;


export function Errors() { 
    const component_name = "admin/errors";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [list, setList] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    //#region Effects
    useEffect(() => {
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(pageLoading) {
            if(current_element_errors) {
                window.w_loadObj(current_element_errors);
            } else {
                window.w_loadObj(".main-content");
            }
        } else {
            if(current_element_errors) {
                window.w_unloadObj(current_element_errors);
                current_element_errors = undefined;
            } else {
                window.w_unloadAll();
            }
            
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        LoadServers();
    }
    //endregion Init


    //#region Loaders
    const LoadServers = async(time = 1) => {
        current_element_errors = ".Errors";
        setPageLoading(true);
        let _servers = await Req("api/Monitor/Errors", "POST", {days: time}, user.token, 0);
        setList(_servers);
        setPageLoading(false);

        timer_requests = setTimeout(() => {LoadServers()}, 60*60*1000);
    }
    //#endregion Loaders


    //#region Handlers
    const handleDaysChange = (event) => {
        clearTimeout(timer_requests);
        LoadServers(event.target.value);
    }


    const getColor = (ms) => {
        if(ms < 700) {
            return "ms700";
        } else if(ms < 2800) {
            return "ms2800";
        } else if(ms >= 2800) {
            return "ms2800u";
        }
    }
    //#endregion Handlers


    return (
        <div className="card Errors">
            <div className="card-header justify-content-between d-flex align-items-center">
                <h4 className="card-title">Last Errors</h4> 
               
                    <select onChange={handleDaysChange}> 
                        <option value={1}>Last 1 day</option>
                        <option value={7}>Last 7 days</option>
                        <option value={15}>Last 15 days</option>
                        <option value={30}>Last 30 days</option>
                    </select>
                
            </div>
            <div id="scroll_servers" className="card-body">                                          
                <div className="table-responsive">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th>Message</th>
                            </tr>
                        </thead>

                        <tbody>
                            {Array.isArray(list) && list.length > 0 && list.map((item, i) => (
                                <tr key={'server'+i} className={getColor(parseInt(item.ellapsed))}>
                                    <td title={moment(item.event_date).format('DD/MM/YYYY hh:mm')}>{item.message}</td>
                                    <td>{moment(item.event_date).format('DD/MM/YYYY hh:mm')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}