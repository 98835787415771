/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { TimeAgo } from '../../scripts/DateTimeFilter';
import './index.css';


export default function Footer() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);




    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        {(new Date().getFullYear())} &copy; AnyDoctor
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end d-none d-sm-block">
                            Último acesso: {TimeAgo(user.acesso)}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}