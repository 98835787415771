import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { GetServer } from './scripts/Loadbalancer';
import { Context } from './Context/AuthContext';
import Login  from './components/Login/Login';
import Layout from './Layout.js';
import Home from './components/Admin/Home';



export default function RouteManager() {
    const location = useLocation();
    const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    const navigate = useNavigate();
    const loadPrestineRef = useRef(false);


    useEffect(() => {
      let wait = setTimeout(() => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
            GetServer();
        }
      }, 200);

    }, [])

    const ProtectedRoute = ({ children }) => {
        const { loading, authenticated } = useContext(Context);
        if (!authenticated && !loading && !isLogin) {
           navigate("/login");
           //return children;
        } else {
            return children;
        }

      };


    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/Home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
               
            </Routes>
        </Layout>
      </Suspense>
   
  );
}